var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      staticClass: "entryupdate",
      attrs: { title: "Dioyu güncelle", active: _vm.activity },
      on: {
        "update:active": function($event) {
          _vm.activity = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "strong",
        { staticClass: "mb-5", staticStyle: { "font-size": "20px" } },
        [_vm._v("\n    " + _vm._s(_vm.alinanItem.topic_name) + "\n  ")]
      ),
      _c("div", {
        ref: "entryhtml",
        staticClass: "mt-3",
        staticStyle: { height: "400px" },
        attrs: { id: "entryhtml", contenteditable: "true" },
        domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.updateEntry)) },
        on: { input: _vm.updateHTML }
      }),
      _vm.entryImages
        ? _c(
            "div",
            { staticClass: "vx-row ml-1  mt-3 image" },
            _vm._l(_vm.entryImages, function(img, index) {
              return _c("div", { key: index }, [
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: {
                      click: function($event) {
                        return _vm.deleteImage(img, index)
                      }
                    }
                  },
                  [_vm._v("×")]
                ),
                _c("img", {
                  staticClass: "rounded image-entry",
                  attrs: { src: img["300"][0] }
                })
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "centerx" },
        [
          _c("vs-upload", {
            attrs: {
              multiple: "",
              text: "Upload Multiple",
              action: "https://jsonplaceholder.typicode.com/posts/"
            },
            on: { "on-success": _vm.successUpload }
          })
        ],
        1
      ),
      _c("vs-button", { staticClass: "mt-3", on: { click: _vm.submit } }, [
        _vm._v("\n    Güncelle\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }