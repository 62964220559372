var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.$store.state.topic_id
        ? _c(
            "vs-button",
            {
              attrs: { color: "dark", type: "line" },
              on: {
                click: function($event) {
                  return _vm.changeVisible("topic")
                }
              }
            },
            [_vm._v("Başlık Dioları")]
          )
        : _vm._e(),
      _c(
        "vs-button",
        {
          class: _vm.all,
          attrs: { color: "primary", type: _vm.all.active ? "line" : "filled" },
          on: {
            click: function($event) {
              return _vm.changeVisible("all")
            }
          }
        },
        [_vm._v("Tüm Diolar")]
      ),
      _c(
        "vs-button",
        {
          class: _vm.deleted,
          attrs: {
            color: "danger",
            type: _vm.deleted.active ? "line" : "filled"
          },
          on: {
            click: function($event) {
              return _vm.changeVisible("deleted")
            }
          }
        },
        [_vm._v("Silinmiş Diolar")]
      ),
      _c("entry-list", {
        attrs: {
          propEntryData: _vm.entryData,
          propTopicName: _vm.topicName,
          isFromTopic: _vm.comingFromTopic,
          entryType: _vm.type
        },
        on: { refreshData: _vm.refreshData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }