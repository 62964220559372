<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table ref="table" v-model="selected" @selected="updateEntry" pagination :max-items="itemsPerPage" :data="entries">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <div v-if="this.isFromTopic" class="h1">
            {{ this.propTopicName }}
          </div>

          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
              <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
                <span class="mr-2">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <span>Delete</span>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <span>Archive</span>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <span>Print</span>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <span>Another Action</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown> -->
        </div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{ entries.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : entries.length }} of {{ entries.length }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>Aksiyon</vs-th>
        <vs-th sort-key="order_status">Durum</vs-th>
        <vs-th sort-key="topic">Diolar</vs-th>
        <vs-th sort-key="createDate">Oluşturulma Tarihi</vs-th>
        <vs-th sort-key="updateDate">Güncellenme Tarihi</vs-th>
        <vs-th sort-key="publishDate">Yayınlanma Tarihi</vs-th>
      </template>

      <template slot-scope="{ data }" id="data">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <vs-button @click="restoreEntry($event, tr.id)" color="dark" type="border" v-if="tr.state.includes('deleted')">
                Geri Getir
              </vs-button>
              <vs-button @click="deleteEntry($event, tr.id)" color="danger" type="border" v-else-if="!tr.state.includes('deleted')">
                Sil
              </vs-button>
            </vs-td>

            <vs-td>
              <div :data="item" :key="index" v-for="(item, index) in tr.state">
                <vs-chip :color="getState(item)" class="product-order-status">{{ getStateText(item) }}</vs-chip>
              </div>
            </vs-td>

            <vs-td id="dio">
              <strong id="entrytopic">{{ tr.topic_name }}</strong>
              <hr class="mt-3" />
              <div id="entryhtml" class="mt-3" v-html="sanitize(tr.entry.entryData)"></div>
              <hr v-if="tr.entry.entryImageUrls" class="mt-3" />
              <div class="vx-row ml-1 mt-3" v-if="tr.entry.entryImageUrls && tr.entry.entryImageUrls.length">
                <div v-for="(img, index) in tr.entry.entryImageUrls" :key="index">
                  {{ img }}
                  <!-- <img :src="img" class="rounded image-entry" /> -->
                </div>
              </div>
            </vs-td>
            <vs-td id="createDate">
              <p class="product-name font-medium">
                {{ tr.createDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="updateDate">
              <p class="product-name font-medium">
                {{ tr.updateDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.updateDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="publishDate">
              <p class="product-name font-medium" v-if="tr.publishDate">
                {{ tr.publishDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.publishDate | moment('HH:mm') }}
              </p>
              <p>-</p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <entry-update
      :activeUpdate="activateUpdate"
      @activityEntryUpdate="changeUpdateActive"
      :updateEntryId="updateEntryId"
      :entry="entry"
      :alinacakItem="gonderilecekItem"
      :type="entryType"
      @refreshData="refreshData"
    ></entry-update>
  </div>
</template>

<script>
import EntryActions from './EntryActions';
import EntryUpdate from './EntryUpdate';
export default {
  name: 'EntryList',
  props: ['propEntryData', 'propTopicName', 'isFromTopic', 'entryType'],
  data() {
    return {
      gonderilecekItem: '',
      updateEntryId: '',
      entry: '',
      activateUpdate: false,
      selected: [],
      entries: [],
      itemsPerPage: 10,
      isMounted: false,
      userLatestPhotos: [
        'user-01.jpg',
        'user-02.jpg',
        'user-04.jpg',
        'user-03.jpg',
        'user-05.jpg',
        'user-06.jpg',
        'user-07.jpg',
        'user-08.jpg',
        'user-09.jpg'
      ]
    };
  },
  components: {
    EntryUpdate
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    }
  },
  methods: {
    refreshData() {
      this.$emit('refreshData', this.entryType);
    },
    updateEntry(selectedItem) {
      this.updateEntryId = selectedItem.id;
      this.gonderilecekItem = selectedItem;
      this.entry = selectedItem.entry.entryData;
      this.activateUpdate = true;
      this.updateEntryTopicId = selectedItem.topicId;
    },
    changeUpdateActive(newValue) {
      this.activateUpdate = newValue;
    },
    sanitize(a) {
      return this.$sanitize(a);
    },
    async restoreEntry(event, id) {
      event.stopPropagation();
      if (confirm("Dio'yu geri getirmek istediğinize emin misiniz?")) {
        var idList = [];
        idList.push(id);
        await EntryActions.restoreEntry(idList);
        this.$emit('refreshData', this.entryType);
      }
    },
    async deleteEntry(event, id) {
      event.stopPropagation();
      if (confirm("Dio'yu silmek istediğinize emin misiniz?")) {
        await EntryActions.deleteEntry(id);
        this.$emit('refreshData', this.entryType);
      }
    },
    getState(state) {
      if (state == 'deleted') {
        return 'danger';
      }
      if (state == 'approved') {
        return 'success';
      }
      if (state == 'restored') {
        return 'light';
      } else if (state == 'waiting') {
        return 'warning';
      }
    },
    getStateText(state) {
      if (state == 'deleted') {
        return 'Silinmiş';
      }
      if (state == 'approved') {
        return 'Onaylanmış';
      }
      if (state == 'restored') {
        return 'Geri Getirilmiş';
      } else if (state == 'waiting') {
        return 'Bekleyen';
      }
    }
  },
  created() {},
  mounted() {
    this.isMounted = true;
  },
  watch: {
    propEntryData(newData) {
      this.entries = newData;
    },
    loader(newData) {
      if (newData) {
        this.$vs.loading();
      } else {
        this.$vs.loading.close();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuesax/pages/profile.scss';
.con-vs-chip {
  font-size: 1.12rem;
}
.vs-button {
  width: 150px;
}
hr {
  border-top: 1px solid #f3f3f3;
}
#entrytopic {
  font-size: 20px;
  color: darkslategray;
}
#entryhtml {
  font-size: 14px;
}
.image-entry {
  width: 50px;
  height: 50px;
}
#dio {
  width: 600px;
}
#createDate {
  width: 130px;
  text-align: center;
}
#updateDate {
  text-align: center;
}
#publishDate {
  text-align: center;
}
#data-list-list-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
