import axios from 'axios'
export default {
    name:"EntryActions",
    base : "http://dio-backend-staging-env3.eu-west-1.elasticbeanstalk.com/v1/1/4/1",
    test: "dio-backend-dev-env3-1.eu-west-1.elasticbeanstalk.com",
    async getAllEntries(){
        let result=[]
        var token=localStorage.getItem('user')
        await axios.post(`${this.base}/entry/list?page=1&perPage=10&all=true`,
        {},{
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data
        })
        return result
    },
    async getTopicEntries(topicID){
        let result=[]
        var token=localStorage.getItem('user')
        await axios.get(`${this.base}/topic/getTopicAndEntriesById/`+topicID+`?page=1&perPage=10`,
        {
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data
        })
        return result
    },
    async getWaitingEntries(){
        let result=[]
        var token=localStorage.getItem('user')
        await axios.post(`${this.base}/entry/list?page=1&perPage=20&deleted=false&draft=true&published=false&edited=false`,
        {},{
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data
        })
        return result
    },
    async getDeletedEntries(){
        let result=[]
        var token=localStorage.getItem('user')
        await axios.post(`${this.base}/entry/list?page=1&perPage=20&deleted=true`,
        {},{
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data
        })
        return result
    },
    async deleteEntry(itemID){
        let result=[]
        var token=localStorage.getItem('user')
        await axios.delete(`${this.base}/entry/delete/soft/${itemID}`,{
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data
        })
        return result
    },
    async restoreEntry(itemIDs){
        var restored_entries = []
        restored_entries=itemIDs
        var body = {
            entryIds: restored_entries
        }
        let result=[]
        var token=localStorage.getItem('user')
        await axios.post(`${this.base}/entry/restore`,
        body,
        {
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data
        })
        return result
    },
    async updateEntry(body){
        var result=""
        var token=localStorage.getItem('user')
        console.log(body)
        await axios.put(`${this.base}/entry/update`,
        body,
        {
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data
        })
        return result
    },
    async getTopicName(topicID){
        var result=""
        var token=localStorage.getItem('user')
        await axios.get(`${this.base}/topic/getById/`+topicID,
        {
            headers: {
                'Authorization':`Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(function (response) {
            result=response.data.data.topic
        })
        return result
    },
}