<template>
  <vs-popup class="entryupdate" title="Dioyu güncelle" :active.sync="activity" @close="close">
    <strong class="mb-5" style="font-size:20px">
      {{ alinanItem.topic_name }}
    </strong>
    <div
      id="entryhtml"
      ref="entryhtml"
      style="height:400px"
      class="mt-3"
      v-html="sanitize(updateEntry)"
      contenteditable="true"
      @input="updateHTML"
    ></div>
    <!-- <div class="editor">
            <div
              class="quill-editor"
              content="content"
              v-quill:myQuillEditor="editorOption"
            ></div>
          </div> -->
    <!--  <div>
            <vs-textarea height="1000px" class="inputx" v-model="updateEntry" :placeholder="updateEntry">
            </vs-textarea>
        </div> -->
    <!-- <div class="vx-row ml-1  mt-3 image" v-if="alinanItem.entry.entryImageUrls">
            <div v-for="(img, index) in alinanItem.entry.entryImageUrls" :key="index">
                 <span class="close" @click="deleteImage(img,index)">&times;</span>
                <img :src="img['300'][0]" class="rounded image-entry">
            </div>
        </div> -->
    <div class="vx-row ml-1  mt-3 image" v-if="entryImages">
      <div v-for="(img, index) in entryImages" :key="index">
        <!-- <button
                    class="btn-x-file"
                    type="button"
                    @click="deleteImage(img,index)">
                    <i
                    translate="no"
                    class="material-icons notranslate">
                    clear
                    </i>
                </button> -->
        <span class="close" @click="deleteImage(img, index)">&times;</span>
        <img :src="img['300'][0]" class="rounded image-entry" />
      </div>
    </div>
    <div class="centerx">
      <vs-upload multiple text="Upload Multiple" action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" />
    </div>
    <vs-button class="mt-3" @click="submit">
      Güncelle
    </vs-button>
  </vs-popup>
</template>

<script>
import EntryActions from './EntryActions';
export default {
  props: ['activeUpdate', 'updateEntryId', 'entry', 'alinacakItem', 'type'],
  data() {
    return {
      activity: false,
      updateEntry: this.entry,
      id: this.updateEntryId,
      alinanItem: this.alinacakItem,
      entryType: this.type,
      topicId: this.alinacakItem.topicId,
      entryImages: [],
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike']]
        },
        placeholder: 'Açıklama alanı..'
      }
    };
  },
  computed: {},
  methods: {
    close() {
      this.$vs.notify({
        color: 'danger',
        text: 'Dio güncellenemedi.'
      });
      this.$emit('activityEntryUpdate', false);
    },
    /* updateHTML: function(e) {
        //e.target.innerHTML= yeni yazılan her şey
        //bunu this.updateEntry'ye eşitle
        // submite basıldığında this.updateEntry yeni yazılan olmalı
      this.$emit('input', e.target.innerHTML);
      console.log(e.target.innerHTML)
      return e.target.innerHTML
      /* this.updateEntry=e.target.innerHTML
      console.log(this.updateEntry)
    }, */
    successUpload() {
      console.log('upload success');
    },
    updateHTML(e) {
      //e.target.innerHTML= yeni yazılan her şey
      //bunu this.updateEntry'ye eşitle
      // submite basıldığında this.updateEntry yeni yazılan olmalı
      this.$emit('input', e.target.innerHTML);
      return e.target.innerHTML;
      /* this.updateEntry=e.target.innerHTML
      console.log(this.updateEntry) */
    },
    sanitize(a) {
      return this.$sanitize(a);
    },
    async submit() {
      //ENTRY GÜNCELLEME İŞLEMİ
      console.log(this.alinanItem.entry.entryImageUrls);
      if (confirm("Dio'yu güncellemek istediğinize emin misiniz?")) {
        console.log(this.alinanItem);
        this.updateEntry = this.$refs.entryhtml.innerHTML;
        let body = {
          id: this.id,
          entry: {
            entryData: this.updateEntry
          },
          topicId: this.topicId
        };

        if (this.entryImages.length) {
          body.entry['entryImageUrls'] = this.entryImages;
        }
        console.log(body);
        await EntryActions.updateEntry(body);
        this.$vs.notify({
          color: 'success',
          text: 'Dio güncellendi.'
        });
        this.$emit('activityEntryUpdate', false);
        this.$emit('refreshData', this.entryType);
      } else {
        this.$vs.notify({
          color: 'danger',
          text: 'Dio güncellenemedi.'
        });
        this.$emit('activityEntryUpdate', false);
      }
    },
    deleteImage(image, index) {
      if (confirm('Resmi silmek istediğinize emin misiniz?')) {
        //RESİM SİLME İŞLEMİ
        this.entryImages.splice(index, 1);
        this.$vs.notify({
          color: 'success',
          text: 'Resim silindi.'
        });
      } else {
        this.$vs.notify({
          color: 'danger',
          text: 'Resim silinemedi.'
        });
      }
    }
  },
  watch: {
    alinacakItem(newData) {
      this.alinanItem = newData;
      this.topicId = newData.topicId;
      this.entryImages = newData.entry.entryImageUrls;
    },
    activeUpdate(newData) {
      this.activity = newData;
    },
    updateEntryId(newData) {
      this.id = newData;
    },
    entry(newData) {
      this.updateEntry = newData;
    },
    type(newData) {
      this.entryType = newData;
    }
  }
};
</script>
<style>
div[contenteditable] {
  border: 2px solid rgba(0, 0, 0, 0.08);
  height: auto;
}

.entryupdate {
  width: 100%;
}
.vs-textarea {
  height: 250px;
}
.image-entry {
  width: 100px;
  height: 100px;
}
.image .close {
  position: absolute;
  z-index: 100;
  background-color: #fff;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.2;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}
.image:hover .close {
  opacity: 1;
}
</style>
<style scoped>
.vs-input--input.normal {
  width: 560px;
}
.vs-popup--content {
  padding: 13px;
  font-size: 0.9em;
  overflow: auto;
  max-height: calc(100vh - 100px);
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: calc(100% - 5px);
  height: 500px;
}
</style>
