<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div >
    <vs-button v-if="this.$store.state.topic_id" color="dark" @click="changeVisible('topic')" type="line">Başlık Dioları</vs-button>
    <vs-button v-bind:class="all" color="primary" :type="all.active ? 'line': 'filled'" @click="changeVisible('all')">Tüm Diolar</vs-button>
   <!--  <vs-button v-bind:class="waiting"  color="warning" :type="waiting.active ? 'line': 'filled'" @click="changeVisible('waiting')">Bekleyen Diolar</vs-button> -->
    <vs-button v-bind:class="deleted" color="danger" :type="deleted.active ? 'line': 'filled'" @click="changeVisible('deleted')">Silinmiş Diolar</vs-button>
    <entry-list :propEntryData=entryData :propTopicName=topicName :isFromTopic=comingFromTopic :entryType=type  @refreshData=refreshData> 
    </entry-list>
  </div>
</template>

<script>
import EntryList from "./EntryList"
import EntryActions from "./EntryActions"
export default {
  data() {
    return {
      entryData:[],
      isMounted: false,
      topicName:"",
      comingFromTopic:false,
      isLoading:false,
      type:"all",
      all: {
          active: this.$store.state.topic_id ? false : true,
        },
      waiting: {
          active: false,
        },
      deleted: {
          active: false,
        },
    }
  },
  components:{
      EntryList
  },
  computed: {
    
  },
  methods: {
    async formatData(data){
        var filtered_data=data
        for(var i=0;i<data.length;i++){
          var topic_name=""
          filtered_data[i]['topic_name']=""
          await EntryActions.getTopicName(data[i].topicId).then( function (value){
            topic_name=value
          }).catch(function(){
            topic_name=""
          })
          filtered_data[i]['topic_name']=topic_name
          filtered_data[i]['state']=[]
          if(data[i].deleted){
              filtered_data[i].state.push("deleted")
          }
          if(data[i].approved){
              filtered_data[i].state.push("approved")
          }
          if(data[i].published){
              filtered_data[i].satae.push("restored")
          }
          else if(data[i].waiting){
              filtered_data[i].state.push("waiting")
          }
        }
        return await filtered_data
    },
    async refreshData(newValue){
      await this.changeVisible(newValue)
    },
    async allEntries(){
      this.$store.dispatch('topic_entry',"")
      this.comingFromTopic=false
      const thisIns = this;
      thisIns.$vs.loading()
      let response= await EntryActions.getAllEntries()
      await thisIns.formatData(response).then(result => {
        thisIns.entryData=result
         thisIns.$vs.loading.close()
      })
      thisIns.type="all"
      localStorage.setItem('route_entry',"all")
    },
    /* async waitingEntries(){
      this.$store.dispatch('topic_entry',"")
      this.comingFromTopic=false
      const thisIns = this;
      thisIns.isLoading=await true
      let response= await EntryActions.getWaitingEntries()
      await thisIns.formatData(response).then(result => {
        thisIns.entryData=result
        thisIns.isLoading=false
      })
    }, */
    async deletedEntries(){
      this.$store.dispatch('topic_entry',"")
      this.comingFromTopic=false
      const thisIns = this;
      thisIns.$vs.loading()
      let response= await EntryActions.getDeletedEntries()
      await thisIns.formatData(response).then(result => {
        thisIns.entryData=result
        thisIns.$vs.loading.close()
      })
      thisIns.type="deleted"
      localStorage.setItem('route_entry',"deleted")
    },
    async topicEntry(topicId){
      const thisIns = this;
     thisIns.$vs.loading()
      let response= await EntryActions.getTopicEntries(topicId)
      await thisIns.formatData(response.entries).then(result => {
        thisIns.entryData=result
        thisIns.$vs.loading.close()
      })
      thisIns.topicName = response.topic
      thisIns.type="topic"
      thisIns.comingFromTopic=true
      localStorage.setItem('route_entry',"topic")
    },
    async changeVisible(visible){
      if(visible==="all"){
        this.all.active=await true
        this.waiting.active=await false
        this.deleted.active=await false
        await this.allEntries()
      }
      /* else if(visible==="waiting"){
        this.all.active=await false
        this.waiting.active=await true
        this.deleted.active=await false
        await this.waitingEntries()
      } */
      else if(visible==="deleted"){
        this.all.active=await false
        this.waiting.active=await false
        this.deleted.active=await true
        await this.deletedEntries()
      }
    }

  },
  async created() {
    if(this.$store.state.topic_id){
      await this.topicEntry(this.$store.state.topic_id)
    }
    else{
      await this.changeVisible(localStorage.getItem('route_entry') || "all")
      //await this.allEntries()
    }
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss" scoped>
.vs-button:hover{
  box-shadow: 0px 0px 0px
}
#data-list-list-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
